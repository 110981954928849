<template>
  <div>
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="subtitle-1 d-flex align-center">
        <router-link class="text-decoration--none" :to="{ name: 'venues' }"
          >Venues</router-link
        >
        <v-icon small class="mx-2"> {{ icons.arrow }} </v-icon>
        <v-skeleton-loader type="chip"></v-skeleton-loader>
      </v-toolbar-title>
    </v-app-bar>
    <div class="ma-4">
      <div class="display-2 font-weight-bold my-6 text-capitalize">
        <v-skeleton-loader type="heading"></v-skeleton-loader>
        <div class="my-6 mt-12 mx-12">
          <v-form ref="form">
            <label class="text-field-label">Venue Name</label>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-row>
              <v-col cols="6">
                <label class="text-field-label">Venue Type</label>
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </v-col>
              <v-col cols="6">
                <label class="text-field-label">Price Range</label>
                <v-skeleton-loader type="text"></v-skeleton-loader>
              </v-col>
            </v-row>

            <label class="text-field-label">Address</label>
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <label class="text-field-label">Phone Number</label>
            <v-skeleton-loader type="text"></v-skeleton-loader>

            <label class="text-field-label">Staff Email</label>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </v-form>
          <v-col cols="12">
            <div class="d-flex align-end">
              <v-btn
                :loading="loading"
                class="ml-auto"
                rounded
                depressed
                large
                color="primary"
              >
                Save Changes
              </v-btn>
            </div>
          </v-col>
        </div>
      </div>

      <div class="d-flex my-6"></div>
    </div>
  </div>
</template>
<script>
import { mdiChevronRight } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
export default {
  components: {
    AppBarNavIcon
  },
  data() {
    return {
      icons: {
        arrow: mdiChevronRight
      },
      loading: true
    }
  }
}
</script>

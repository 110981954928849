<template>
  <div>
    <div v-if="venue">
      <v-app-bar flat color="transparent">
        <app-bar-nav-icon />
        <v-toolbar-title class="subtitle-1 d-flex align-center">
          <router-link class="text-decoration--none" :to="{ name: 'venues' }"
            >Venues</router-link
          >
          <v-icon small class="mx-2"> {{ icons.arrow }} </v-icon>
          <router-link
            class="text-decoration--none"
            :to="{ name: 'venues.view', params: { id: venue.id } }"
          >
            {{ venue.name }}
          </router-link>
          <v-icon small class="mx-2"> {{ icons.arrow }} </v-icon>
          Edit
        </v-toolbar-title>
      </v-app-bar>
      <v-row>
        <v-col>
          <div class="display-2 font-weight-bold my-6 text-capitalize">
            {{ venue.name }}
          </div>

          <div class="my-6 mt-12 mx-12">
            <form-label required>Venue Photos</form-label>
            <media-uploader
              class="mb-6 mt-2"
              :loading.sync="venuePhotosUploading"
              :storedFiles="storedVenuePhotos"
              @onFileUploadSuccess="venuePhotoAdded"
              @onFileRemove="venuePhotoRemoved"
              :error="hasError"
            />
            <div class="v-messages theme--light error--text mb-3" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">{{ photosError }}</div>
              </div>
            </div>

            <v-form ref="form" v-if="form">
              <form-label required>Venue Name</form-label>
              <v-text-field
                flat
                required
                outlined
                class="mt-2"
                v-model="form.name"
                :error-messages="errors.name"
              ></v-text-field>
              <v-row>
                <v-col cols="6">
                  <form-label required>Venue Type</form-label>
                  <v-select
                    v-if="form.type"
                    :items="venueTypes"
                    item-text="name"
                    item-value="id"
                    v-model="form.venue_type_id"
                    flat
                    class="mt-2"
                    outlined
                    :error-messages="errors.venue_type_id"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <form-label required>Price Range</form-label>
                  <v-select
                    :items="price_ranges"
                    flat
                    class="mt-2"
                    outlined
                    v-model="form.price_range"
                    :error-messages="errors.price_range"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <label class="text-field-label">Tags</label>
              <v-autocomplete
                :disabled="loading"
                :items="tags"
                v-model="form.tags"
                chips
                label="Select tag/s"
                item-text="tag"
                return-object
                multiple
                outlined
                class="mt-2"
                flat
                :error-messages="errors.tags"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.tag }}
                  </v-chip>
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.tag"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <form-label required>Address</form-label>
              <places-search
                background-color="grey lighten-3"
                :error-messages="errors.address"
                @selected="addressSelected"
                :pre-fill-items="addresses"
                :value="form.place_id"
              ></places-search>
              <form-label required>Phone Number</form-label>
              <v-text-field
                :error-messages="errors.phone_number"
                flat
                required
                outlined
                class="mt-2"
                v-model="form.phone_number"
              ></v-text-field>

              <form-label required>Staff Email</form-label>
              <v-text-field
                :error-messages="errors.staff_email"
                v-if="form.staff"
                flat
                required
                outlined
                class="mt-2"
                v-model="form.staff_email"
              ></v-text-field>
              <label class="text-field-label">Description</label>
              <v-textarea
                flat
                outlined
                required
                placeholder="Type in venue description"
                class="mt-2"
                v-model="form.description"
                :error-messages="errors.description"
              ></v-textarea>
              <schedule
                @changed="timeUpdated"
                :hours="form.venue_hours"
                :errors="errors"
              ></schedule>
            </v-form>
            <v-col cols="12">
              <div class="d-flex align-end">
                <v-btn
                  :loading="saveLoading"
                  class="ml-auto"
                  rounded
                  depressed
                  large
                  color="primary"
                  @click="saveChanges"
                >
                  Save Changes
                </v-btn>
              </div>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
    <edit-loading v-else></edit-loading>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import { each, findIndex, map } from 'lodash'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import EditLoading from '@/views/Home/Venues/Edit/loading'
import PlacesSearch from '@/components/places/places-search'
import Venue from '@/models/Venue'
import VenueTag from '@/models/VenueTag'
import Media from '@/models/Media'
import MediaUploader from '@/components/dropzone/media-uploader'
import Schedule from '@/components/Venues/Schedule'

export default {
  name: 'VenueDetails',
  components: {
    AppBarNavIcon,
    EditLoading,
    PlacesSearch,
    MediaUploader,
    Schedule
  },
  data() {
    return {
      icons: {
        arrow: mdiChevronRight
      },
      price_ranges: ['$', '$$', '$$$', '$$$$'],
      loading: false,
      venuePhotosUploading: false,
      storedVenuePhotos: [],
      deletebleMediaIds: [],
      saveLoading: false,
      deleteLoading: false,
      deleteDialog: false,
      tags: [],
      addresses: [],
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      form: {
        venue_photos: []
      },
      venue: null,
      errors: {}
    }
  },

  computed: {
    ...mapState({
      venueTypes: state => state.venueType.list
    }),
    hasError() {
      if (this.errors.venue_photos && this.errors.venue_photos.length > 0) {
        return true
      }
      return false
    },
    photosError() {
      if (this.errors.venue_photos && this.errors.venue_photos.length > 0) {
        return this.errors.venue_photos[0]
      }
      return ''
    }
  },

  methods: {
    ...mapActions({
      getVenueTypes: 'venueType/getVenueTypes'
    }),

    ...mapMutations({
      clearVenueDetails: 'venue/clearVenueDetails'
    }),

    async getVenue() {
      this.loading = true
      await Venue.find(this.$route.params.id).then(d => {
        this.venue = d.data
        this.form = new Venue(d.data)
        this.form.venue_photos = map(d.data.venue_photos, p => p.id)
        this.form.venue_hours = this.form.venueHours()
        this.storedVenuePhotos = d.data.venue_photos
      })
      this.loading = false
    },
    async fetchVenueTypes() {
      this.loading = true
      await this.getVenueTypes({ page: 1 })
      this.loading = false
    },

    async saveChanges() {
      this.saveLoading = true
      this.errors = {}
      await this.form
        .save()
        .then(d => {
          this.venue = d
          this.removeDeletableMedia().then(() => {
            this.loading = false
            this.showSnackbar('Venue updated successfully!', 'success')
          })
          this.errors = {}
        })
        .catch(e => {
          this.errors = e.response.data.errors
        })

      this.saveLoading = false
    },

    async getTags() {
      this.loading = true
      await VenueTag.page(1)
        .get()
        .then(d => {
          each(d.data, tag => {
            this.tags.push(tag)
          })
        })
      this.loading = false
    },

    remove(item) {
      const index = findIndex(this.form.tags, { id: item.id })
      if (index >= 0) this.form.tags.splice(index, 1)
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    addressSelected(place) {
      this.form.address = ''
      this.form.place_id = ''
      this.form.address = place.description
      this.form.place_id = place.place_id
    },

    venuePhotoAdded(media) {
      this.form.venue_photos.push(media.id)
    },

    async removeDeletableMedia() {
      const deleting = []
      each(this.deletebleMediaIds, mediaId => {
        deleting.push(new Media({ id: mediaId }).delete())
      })
      await Promise.all(deleting)
    },

    venuePhotoRemoved(id) {
      const index = findIndex(this.form.venue_photos, v => v === id)
      if (index > -1) {
        this.deletebleMediaIds.push(id)
        this.form.venue_photos.splice(index, 1)
      }
    },
    timeUpdated(time) {
      this.form.venue_hours = time
    }
  },

  created() {
    this.getVenue()
    this.fetchVenueTypes()
    this.getTags()
  },

  destroyed() {
    this.clearVenueDetails()
  },

  watch: {
    $route() {
      this.getVenue()
    },

    form(val) {
      this.addresses.push({
        place_id: val.place_id,
        description: val.address
      })
    }
  }
}
</script>
